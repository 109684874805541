// Source: https://docs.google.com/spreadsheets/d/1Sr0MUrudC_BJORGbxEx4h_9xkIre9AxBSM5UDrHMt_I/edit?gid=0#gid=0

export const InternetPackageType = {
  HOUSE: 'RODINNÝ DŮM',
  FLAT: 'OBYTNÝ DŮM',
}

export const InternetPackage = {
  HOUSE_40: {
    type: InternetPackageType.HOUSE,
    speed: 40,
    price: 379,
  },
  HOUSE_50: {
    type: InternetPackageType.HOUSE,
    speed: 50,
    price: 449,
  },
  HOUSE_100: {
    type: InternetPackageType.HOUSE,
    speed: 100,
    price: 379,
  },
  HOUSE_200: {
    type: InternetPackageType.HOUSE,
    speed: 200,
    price: 449,
  },
  HOUSE_300: {
    type: InternetPackageType.HOUSE,
    speed: 300,
    price: 379,
  },
  HOUSE_600: {
    type: InternetPackageType.HOUSE,
    speed: 600,
    price: 449,
  },
  HOUSE_700: {
    type: InternetPackageType.HOUSE,
    speed: 700,
    price: 399,
  },
  HOUSE_1000: {
    type: InternetPackageType.HOUSE,
    speed: 1000,
    price: 449,
  },
  HOUSE_2000: {
    type: InternetPackageType.HOUSE,
    speed: 2000,
    price: 555,
  },
  HOUSE_10000: {
    type: InternetPackageType.HOUSE,
    speed: 10000,
    price: 2999,
  },
  FLAT_300: {
    type: InternetPackageType.FLAT,
    speed: 300,
    price: 379,
  },
  FLAT_600: {
    type: InternetPackageType.FLAT,
    speed: 600,
    price: 449,
  },
  FLAT_700: {
    type: InternetPackageType.FLAT,
    speed: 700,
    price: 399,
  },
  FLAT_1000: {
    type: InternetPackageType.FLAT,
    speed: 1000,
    price: 449,
  },
  FLAT_2000: {
    type: InternetPackageType.FLAT,
    speed: 2000,
    price: 555,
  },
  FLAT_10000: {
    type: InternetPackageType.FLAT,
    speed: 10000,
    price: 2999,
  },
}

export const internetPackages = {
  1: { id: 1, ...InternetPackage.HOUSE_40 },
  2: { id: 2, ...InternetPackage.HOUSE_50 },
  3: { id: 3, ...InternetPackage.HOUSE_100 },
  4: { id: 4, ...InternetPackage.HOUSE_200 },
  5: { id: 5, ...InternetPackage.HOUSE_300 },
  6: { id: 6, ...InternetPackage.HOUSE_600 },
  7: { id: 7, ...InternetPackage.HOUSE_700 },
  8: { id: 8, ...InternetPackage.HOUSE_1000 },
  9: { id: 9, ...InternetPackage.HOUSE_2000 },
  10: { id: 10, ...InternetPackage.HOUSE_10000 },
  11: { id: 11, ...InternetPackage.FLAT_300 },
  12: { id: 12, ...InternetPackage.FLAT_600 },
  13: { id: 13, ...InternetPackage.FLAT_700 },
  14: { id: 14, ...InternetPackage.FLAT_1000 },
  15: { id: 15, ...InternetPackage.FLAT_2000 },
  16: { id: 16, ...InternetPackage.FLAT_10000 },
}

export const internetApiToPackages = {
  1: [internetPackages[1], internetPackages[2]],
  2: [internetPackages[13], internetPackages[14]],
  3: [internetPackages[11], internetPackages[12]],
  4: [
    internetPackages[7],
    internetPackages[8],
    internetPackages[9],
    internetPackages[13],
    internetPackages[14],
    internetPackages[15],
  ],
  5: [
    internetPackages[7],
    internetPackages[8],
    internetPackages[9],
    internetPackages[13],
    internetPackages[14],
    internetPackages[15],
  ],
  6: [internetPackages[11], internetPackages[12]],
  7: [internetPackages[11], internetPackages[12]],
  8: [internetPackages[11], internetPackages[12]],
  9: [internetPackages[11], internetPackages[12]],
  10: [internetPackages[13], internetPackages[14]],
  11: [internetPackages[11], internetPackages[12]],
  12: [internetPackages[11], internetPackages[12]],
  13: [internetPackages[11], internetPackages[12]],
  15: [internetPackages[3], internetPackages[4]],
  16: [internetPackages[11], internetPackages[12]],
  17: [
    internetPackages[5],
    internetPackages[6],
    internetPackages[11],
    internetPackages[12],
  ],
  18: [
    internetPackages[13],
    internetPackages[14],
    internetPackages[15],
    internetPackages[16],
  ],
  19: [internetPackages[11], internetPackages[12]],
}

export const internetBonusPackages = {
  0: { id: 0, title: 'Veřejná IPV4 adresa', price: 100 },
  1: { id: 1, title: 'Rozšíření WiFi signálu', price: 25 },
}
