export const tvPackages = {
  basic: {
    title: 'Základ',
    price: 145,
  },
  standard: {
    title: 'Standard',
    price: 399,
  },
  complete: {
    title: 'Komplet',
    price: 869,
  },
}

export const bonusPackages = {
  'Pro děti': { id: 'Pro děti', title: 'Pro děti', price: 79 },
  Filmbox: { id: 'Filmbox', title: 'Filmbox', price: 249 },
  Plus: { id: 'Plus', title: 'Plus', price: 119 },
  HBO: { id: 'HBO', title: 'HBO', price: 199 },
  'HBO MAX': { id: 'HBO MAX', title: 'HBO Max', price: 333 },
  Cinemax: { id: 'Cinemax', title: 'Cinemax', price: 199 },
  Sport: { id: 'Sport', title: 'Sport', price: 199 },
  '4K': { id: '4K', title: '4k-UHD', price: 29 },
}
