export const useCart = async () => {
  const { planPackages } = useTvChannels()
  const { phonePlans, fetch, loading: loadingPhonePlanes } = usePhonePlans()

  await fetch()

  const loading = computed(() => loadingPhonePlanes.value)

  const tvPackagesInCart = useLocalStorageOnMounted<string[]>(
    'tvPackagesInCart',
    []
  )

  const tvBonusPackagesInCart = useLocalStorageOnMounted<string[]>(
    'tvBonusPackagesInCart',
    []
  )

  const tvExtrasInCart = useLocalStorageOnMounted<
    { title: string; price: number }[]
  >('tvExtrasInCart', [])

  const phonePlansInCart = useLocalStorageOnMounted<number[]>(
    'phonePlansInCart',
    []
  )

  const internetPackagesInCart =
    useLocalStorageOnMounted<InternetPackagesInCart>(
      'internetPackagesInCart',
      []
    )

  const internetBonusPackagesInCart = useLocalStorageOnMounted<number[]>(
    'internetBonusPackagesInCart',
    []
  )

  const itemsInCart = computed(() => {
    return [
      ...tvPackagesInCart.value,
      ...phonePlansInCart.value,
      ...tvBonusPackagesInCart.value,
      ...internetPackagesInCart.value,
      ...internetBonusPackagesInCart.value,
      ...tvExtrasInCart.value,
    ].filter((item) => item !== null && item !== undefined).length
  })

  const tvBonusPackagesByCount = computed(() => {
    const bonusPackageCount: Record<string, number> = {}

    tvBonusPackagesInCart.value.forEach((pkg) => {
      if (bonusPackageCount[pkg]) {
        bonusPackageCount[pkg]++
      } else {
        bonusPackageCount[pkg] = 1
      }
    })

    return Object.keys(bonusPackageCount)
      .map((key) => ({
        id: key,
        title: bonusPackages[key as keyof typeof bonusPackages].title,
        count: bonusPackageCount[key],
        price: bonusPackages[key as keyof typeof bonusPackages].price,
        totalPrice:
          bonusPackages[key as keyof typeof bonusPackages].price *
          bonusPackageCount[key],
      }))
      .sort((a, b) => a.id.localeCompare(b.id))
  })

  const tvPackagesByCount = computed(() => {
    const packageCount: Record<string, number> = {}

    tvPackagesInCart.value.forEach((pkg) => {
      if (packageCount[pkg]) {
        packageCount[pkg]++
      } else {
        packageCount[pkg] = 1
      }
    })

    return Object.keys(packageCount)
      .map((key) => ({
        id: key,
        title: planPackages.value[key as keyof typeof planPackages.value].title,
        count: packageCount[key],
        price: planPackages.value[key as keyof typeof planPackages.value].price,
        totalPrice:
          planPackages.value[key as keyof typeof planPackages.value].price *
          packageCount[key],
      }))
      .sort((a, b) => a.title.localeCompare(b.title))
  })

  const tvExtrasByCount = computed(() => {
    const extrasCount: Record<
      string,
      { title: string; price: number; count: number }
    > = {}

    tvExtrasInCart.value.forEach((pkg) => {
      if (extrasCount[pkg.title]) {
        extrasCount[pkg.title].count++
      } else {
        extrasCount[pkg.title] = { ...pkg, count: 1 }
      }
    })

    return Object.values(extrasCount)
      .map((value) => ({
        title: value.title,
        count: value.count,
        price: value.price,
        totalPrice: value.price * value.count,
      }))
      .sort((a, b) => a.title.localeCompare(b.title))
  })

  const phonePlansByCount = computed(() => {
    const packageCount: Record<number, number> = {}

    phonePlansInCart.value.forEach((pkg) => {
      if (packageCount[pkg]) {
        packageCount[pkg]++
      } else {
        packageCount[pkg] = 1
      }
    })

    const plans = Object.keys(packageCount)
      .map((key) => {
        const data = phonePlans.value?.find((p) => p.id === +key)

        console.log(data)

        if (!data) {
          return null
        }

        return {
          id: +key,
          data: data.data,
          title: data.title,
          count: packageCount[+key],
          price: data.price,
          totalPrice: data.price * packageCount[+key],
        }
      })
      .filter((pkg) => !!pkg)
      .sort((a, b) => (a.id < b.id ? -1 : 1))

    console.log('plans', plans)

    return plans
  })

  const internetBonusPackagesByCount = computed(() => {
    const packageCount: Record<number, number> = {}

    internetBonusPackagesInCart.value.forEach((pkg) => {
      if (packageCount[pkg]) {
        packageCount[pkg]++
      } else {
        packageCount[pkg] = 1
      }
    })

    return Object.keys(packageCount)
      .map((key) => ({
        id: +key,
        title:
          internetBonusPackages[+key as keyof typeof internetBonusPackages]
            .title,
        count: packageCount[+key],
        price:
          internetBonusPackages[+key as keyof typeof internetBonusPackages]
            .price,
        totalPrice:
          internetBonusPackages[+key as keyof typeof internetBonusPackages]
            .price * packageCount[+key],
      }))
      .sort((a, b) => (a.id < b.id ? -1 : 1))
  })

  const internetPackagesByCount = computed(() => {
    return internetPackagesInCart.value.map((pckg) => ({
      id: pckg.internetPackageId,
      address: pckg.address,
      speed:
        internetPackages[
          pckg.internetPackageId as keyof typeof internetPackages
        ].speed,
      price:
        internetPackages[
          pckg.internetPackageId as keyof typeof internetPackages
        ].price,
    }))
  })

  const totalPrice = computed(() => {
    let total = 0

    tvPackagesByCount.value.forEach((pkg) => {
      total += pkg.totalPrice
    })

    tvBonusPackagesByCount.value.forEach((pkg) => {
      total +=
        bonusPackages[pkg.id as keyof typeof bonusPackages].price * pkg.count
    })

    phonePlansByCount.value.forEach((pkg) => {
      total +=
        (phonePlans.value?.find((p) => p.id === pkg.id)?.price || 0) * pkg.count
    })

    internetPackagesInCart.value.forEach((pkg) => {
      total +=
        internetPackages[pkg.internetPackageId as keyof typeof internetPackages]
          .price
    })

    internetBonusPackagesByCount.value.forEach((pkg) => {
      total += pkg.price * pkg.count
    })

    tvExtrasByCount.value.forEach((pkg) => {
      total += pkg.totalPrice
    })

    return total
  })

  function emptyTvPackages() {
    tvPackagesInCart.value = []
  }

  function emptyTvBonusPackages() {
    tvBonusPackagesInCart.value = []
  }

  function emptyTvExtras() {
    tvExtrasInCart.value = []
  }

  function emptyPhonePlans() {
    phonePlansInCart.value = []
  }

  function emptyInternetPackages() {
    internetPackagesInCart.value = []
  }

  function emptyInternetBonusPackages() {
    internetBonusPackagesInCart.value = []
  }

  function emptyCart() {
    emptyTvPackages()
    emptyTvBonusPackages()
    emptyTvExtras()
    emptyPhonePlans()
    emptyInternetPackages()
    emptyInternetBonusPackages()
  }

  return {
    tvPackagesInCart,
    tvBonusPackagesInCart,
    tvExtrasInCart,
    phonePlansInCart,
    internetPackagesInCart,
    internetBonusPackagesInCart,
    itemsInCart,
    tvBonusPackagesByCount,
    tvPackagesByCount,
    tvExtrasByCount,
    phonePlansByCount,
    internetPackagesByCount,
    internetBonusPackagesByCount,
    totalPrice,
    emptyTvPackages,
    emptyTvBonusPackages,
    emptyTvExtras,
    emptyPhonePlans,
    emptyInternetPackages,
    emptyInternetBonusPackages,
    emptyCart,
    loading,
  }
}

export type InternetPackagesInCart = {
  address: string
  internetPackageId: number
}[]

function useLocalStorageOnMounted<T>(key: string, initialValue: T) {
  return useLocalStorage<T>(key, initialValue, { initOnMounted: true })
}
