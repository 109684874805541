export function usePhonePlans() {
  const {
    data: phonePlans,
    status,
    error,
    execute,
  } = useFetch('/api/mobile-plans', {
    immediate: false,
  })

  async function fetch() {
    if (status.value === 'success') return
    await execute()
  }

  const loading = computed(() => status.value === 'pending')

  return {
    fetch,
    phonePlans,
    loading,
  }
}
