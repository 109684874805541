import type { Channel } from '~/types/Channel'

export function useTvChannels() {
  const {
    data: channels,
    status,
    error,
    execute,
  } = useLazyFetch<Channel[]>(
    'https://admin.geniustv.cz/api/v1/public/channels',
    {
      immediate: false,
    }
  )

  async function fetchChannels() {
    if (status.value === 'success') return
    await execute()
  }

  const packages = computed(() =>
    channels.value
      ?.flatMap((channel) =>
        channel.channelPackages.split(',').map((p) => p.replace('G.TV ', ''))
      )
      .filter((value) => value.length)
      .filter((value, index, self) => self.indexOf(value) === index)
      .filter((value) => !['Komplet', 'Základ', 'Standard'].includes(value))
  )

  const channelsByPackages = computed(() =>
    packages.value?.reduce(
      (acc: Record<string, Channel[] | undefined>, pkg) => {
        acc[pkg] = channels.value?.filter((channel) =>
          channel.channelPackages.match(String.raw`${pkg},|${pkg}$`)
        )
        return acc
      },
      {}
    )
  )

  const chosenPackage = ref(packages.value?.at(0) || 'Filmbox')

  const basicChannels = computed(() =>
    channels.value?.filter((channel) =>
      channel.channelPackages.includes('Základ')
    )
  )

  const standardChannels = computed(() =>
    channels.value?.filter((channel) =>
      channel.channelPackages.includes('Standard')
    )
  )

  const completeChannels = computed(() =>
    channels.value?.filter((channel) =>
      channel.channelPackages.includes('Komplet')
    )
  )

  const banners: { title: string[]; icon: Icon; type?: 'white' | 'light' }[] = [
    {
      title: ['Aplikace pro', 'SMART TV ZDARMA'],
      icon: 'ikona_aplikace',
    },
    {
      title: ['Přetáčení až 14 dní zpět'],
      icon: 'ikona_pretaceni_m',
      type: 'white',
    },
    {
      title: ['Nahrávání 10 hodin'],
      icon: 'ikona_nahravani',
    },
    {
      title: ['Sledování v telefonu,', 'tabletu a PC'],
      icon: 'ikona_sledovani_m',
      type: 'white',
    },
  ]

  const lightBanners = [
    {
      title: ['Aplikace pro', 'SMART TV ZDARMA'],
      icon: 'ikona_aplikace_m',
      type: 'light',
    },
    {
      title: ['Přetáčení až 14 dní zpět'],
      icon: 'ikona_pretaceni_m',
      type: 'white',
    },
    {
      title: ['Nahrávání 10 hodin'],
      icon: 'ikona_nahravani_m',
      type: 'light',
    },
    {
      title: ['Sledování v telefonu,', 'tabletu a PC'],
      icon: 'ikona_sledovani_m',
      type: 'white',
    },
  ]

  const planPackages = computed(() => ({
    basic: {
      title: 'Základ',
      channels: basicChannels.value?.filter((ch) => !ch.is_radio),
      channelsCount: basicChannels.value?.filter((ch) => !ch.is_radio)?.length,
      hdChannelsCount: basicChannels.value?.filter(
        (ch) => !ch.is_radio && ch.kvalita === 'HD'
      )?.length,
      price: 145,
      banners,
      image: '/img/Grapik_tv_leva.png',
    },
    standard: {
      title: 'Standard',
      channels: standardChannels.value?.filter((ch) => !ch.is_radio),
      channelsCount: standardChannels.value?.filter((ch) => !ch.is_radio)
        ?.length,
      hdChannelsCount: standardChannels.value?.filter(
        (ch) => !ch.is_radio && ch.kvalita === 'HD'
      )?.length,
      price: 399,
      banners: lightBanners,
      image: '/img/Grapik_tv_stred.png',
    },
    complete: {
      title: 'Komplet',
      channels: completeChannels.value?.filter((ch) => !ch.is_radio),
      channelsCount: completeChannels.value?.filter((ch) => !ch.is_radio)
        ?.length,
      hdChannelsCount: completeChannels.value?.filter(
        (ch) => !ch.is_radio && ch.kvalita === 'HD'
      )?.length,
      price: 869,
      banners,
      image: '/img/Grapik_tv_prava.png',
    },
  }))

  return {
    channels,
    status,
    error,
    packages,
    channelsByPackages,
    chosenPackage,
    basicChannels,
    standardChannels,
    completeChannels,
    planPackages,
    fetchChannels,
  }
}
